













































































































import { Component, Prop, Vue } from "vue-property-decorator";
// @ts-ignore
import Plyr from "plyr";
import "plyr/dist/plyr.css";

@Component
export default class DynamicVideo extends Vue {
  @Prop() image?: string;

  @Prop() videoUrl?: string;

  ready = false;

  mounted() {
    if (document.getElementById("player") != null) {
      const player = new Plyr("#player", {
        volume: 0.4,
        hideControls: false,
      });

      player.toggleControls(false);

      if (this.image) player.poster = this.image;

      player.on("play", () => {
        player.toggleControls(true);
      });

      this.ready = true;
    }
  }

  get vimeoId() {
    const urlParts = this.videoUrl?.split("/");
    if (urlParts) {
      return urlParts[urlParts.length - 1];
    }
    return "";
  }

  get youtubeId() {
    if (this.videoUrl) {
      var url = new URL(this.videoUrl);
      return url.searchParams.get("v");
    }
    return "";
  }
}
